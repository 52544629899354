// @flow

import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@latitude/box';
import { Link } from '@latitude/link';
import { Heading3 } from '@latitude/heading';
import { Text } from '@latitude/text';
import {
  BREAKPOINT,
  COLOR,
  MARGIN,
  ALIGN
} from '@latitude/core/utils/constants';

type Props = {
  /** Specify a heading. */
  heading: string,
  /** Specify a subheading. */
  subheading: string,
  /** Specify a data. */
  data: [],
  /** Removes box shadow and background styling */
  noShadow: boolean
};

type LinkObject = {
  title: string,
  href: string,
  trackId: string,
  imageSrc: string,
  width?: string
};

const defaultItems: LinkObject[] = [
  {
    title: 'Apple Pay',
    href: '/digital-wallets/apple-pay/',
    trackId: 'digital-wallets-apple-pay',
    imageSrc: require('./svg/logo-apple-pay-branded.svg')
  },
  {
    title: 'Google Pay',
    href: '/digital-wallets/google-pay/',
    trackId: 'digital-wallets-google-pay',
    imageSrc: require('./svg/logo-g-pay-branded.svg')
  },
  {
    title: 'Garmin Pay',
    href: '/digital-wallets/garmin-pay/',
    trackId: 'digital-wallets-garmin-pay',
    imageSrc: require('./svg/logo-garmin-pay-branded.svg')
  }
];

const DigitalWallets = ({
  heading = 'Digital wallets',
  subheading = 'You can pay any way on your phone or wearable tech device with just a tap.',
  data = defaultItems,
  noShadow
}: Props) => (
  <Container id="digital-wallets">
    <Box isResponsive margin="0 auto">
      <Heading3 color={COLOR.BLACK} align={ALIGN.CENTER}>
        {heading}
      </Heading3>
      <Subheading>{subheading}</Subheading>
      <LinkItems>
        {data.map((item: LinkObject, i: number) => (
          <LinkItem
            key={i}
            noStyle
            noShadow={noShadow}
            href={item.href}
            trackId={`${item.trackId}`}
            trackEventData={{
                label: item.title,
                location: heading
              }}
          >
            <img src={item.imageSrc} alt={item.title} width={item.width} />
          </LinkItem>
          ))}
      </LinkItems>
    </Box>
  </Container>
  );

const Container = styled(Box)`
  position: relative;
  background-color: #f9f9f9;
  padding: 40px 0;

  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 72px 0;
  }
`;

const Subheading = styled(Text)`
  max-width: 600px;
  margin: 0 auto ${MARGIN.M32};
  text-align: center;
`;

const LinkItems = styled(Flex)`
  margin-top: -${MARGIN.M24};
  flex-wrap: wrap;
  justify-content: center;
`;

const LinkItem = styled(Link)`
  margin: ${MARGIN.M24} calc(${MARGIN.M24} / 2) 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 180px;
  height: 48px;

  border-radius: 4px;

  background: ${COLOR.WHITE};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  }

  /** Override for background and box-shadow **/
  ${(props: string) =>
    props.noShadow &&
    `box-shadow: none; background: none;
      &:hover { box-shadow: none; }`}
`;

export default DigitalWallets;
