import React from 'react';
import PropTypes from 'prop-types';

class Image extends React.Component {
  constructor(props) {
    super(props);
  }

  onImageLoad() {
    // Could fade in here
  }

  render() {
    const className = `img-fluid ${this.props.className || ''}`;
    const newProps = {...this.props, className}
    return (
      <img
        onLoad={this.onImageLoad}
        {...newProps}
      />
    );
  }
}

Image.propTypes = {
  src:  PropTypes.string
};

export default Image;
